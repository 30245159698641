import { AlertsManager, XLocation, LocationMethod, ReactionLocation, SavedLocation } from '../alerts-manager/alerts-manager';
import { log, iterationCopy, logError } from '../../helpers/utils';
import { Coordinates } from '../../global/types';

export class LocationManager {
  am: AlertsManager = null;

  coordinates: Coordinates; //from geo only for local use
  cityName: string;
  urlRoot: string = "$api$";
  urlLocate: string = this.urlRoot + "/api/locate?route={route}";
  currentRoute: any = '';
  tabPath: string = 'home';

  requestedLocation: XLocation = null;
  currentLocation: XLocation = null;
  defaultLocation: XLocation = null;

  constructor(am: AlertsManager) {
    this.am = am;

    AlertsManager.onEvent('location-manager', 'route-changed', { fn: e => this.routeChanged(e) });
    AlertsManager.onEvent('location-manager', 'tabs-changed', {
      fn: e => {
        this.tabPath = e.path;
        //last part! HERE!!!

        if (this.currentLocation && this.tabPath) {
          log('set am tabPath=' + this.tabPath + ", route=" + this.currentLocation.route, 'trace,location', 'location-manager');
          this.setPath(this.tabPath, this.currentLocation.route);
        } else {
          log('cannot set am tabPath=' + this.tabPath + " due to no route=" + JSON.stringify(this.currentLocation), 'trace,location', 'location-manager');
        }

        // log('set am tabPath=' + this.tabPath, 'trace,location', 'location-manager');
        // if (this.data && this.data.location && this.data.location.route) {
        //     if (this.tabPath && this.tabPath.indexOf('profile') === -1) {
        //         log('set HISTORY:' + this.tabPath + this.data.location.route, 'trace,location', 'location-manager');
        //         AlertsManager.instance.setLocation(this.data.location);
        //         history.pushState(null, '', '/' + this.tabPath + this.data.location.route);
        //     }
        // }
      }
    });

    AlertsManager.onEvent('location-manager', 'location-changed', {
      fn: e => { if (e.loc) this.requestedLocation = e.loc; }
    });
  }

  setPath(tab: string, route: string) {
    try {
      var path = ('/' + tab + route).replace('//', '/');
      log('setPath: ' + path, 'trace,location', 'location-manager');
      if (path.indexOf('/profile') === -1) {
        history.pushState(null, '', path);
      }
    } catch (ee) {
      logError(ee);
    }
  }

  getLocationSync(fromData: boolean = true): XLocation {
    return fromData ? this.currentLocation : this.requestedLocation;
  }

  getLocation(fromData: boolean = true): XLocation {
    return fromData ? this.currentLocation : this.requestedLocation;
  }

  getCityName(fromData: boolean = true): string {
    var loc = fromData ? this.currentLocation : this.requestedLocation;
    return loc ? XLocation.cityName(loc) : "(loading)";
  }

  async getCurrentLocation() {
    var promise = new Promise<XLocation>(async (resolve, reject) => {
      try {
        if (this.am.settings && this.am.settings.location && this.am.settings.location.currentLocationEnabled) {
          if ("geolocation" in navigator) {
            await navigator.geolocation.getCurrentPosition((position) => {

              var co = {
                lat: Math.floor(position.coords.latitude * 100) / 100,
                lon: Math.floor(position.coords.longitude * 100) / 100
              }

              log('using geolocation(' + co.lat + ',' + co.lon + ')', 'trace,location', 'location-manager');
              this.coordinates = position.coords;

              var loc: XLocation = {
                method: LocationMethod.latLon,
                lat: co.lat,
                lon: co.lon
              };

              this.requestedLocation = loc;

              resolve(loc);
              return loc;
            });
          } else {
            log("geolocation is not supported in this environment", 'error,location', 'location-manager');
            this.coordinates = {
              latitude: 0,
              longitude: 0,
              accuracy: 0,
              altitude: 0,
              altitudeAccuracy: 0,
              heading: 0,
              speed: 0
            };

            var loc: XLocation = {
              method: LocationMethod.unknown,
              lat: 0,
              lon: 0
            };

            this.requestedLocation = loc;

            resolve(loc);
            return loc;
          }
        } else {
          resolve(this.requestedLocation || this.defaultLocation);
          return this.requestedLocation || this.defaultLocation;
        }
      } catch (e) {
        logError(e, 'getCurrentLocation issue');
        reject();
      }
    });

    return promise;
  }

  // systemRequestedChange(newLoc: XLocation) {

  // }

  compare(loc1: XLocation, loc2: XLocation): boolean {
    if (loc1 === null || loc2 === null) {
      return false;
    }

    return loc1.route === loc2.route ||
      (loc1.city === loc2.city && loc1.state === loc2.state);
  }

  setDefaultLocation(loc: XLocation) {
    this.defaultLocation = loc;
  }

  //from user request (or load)
  changeLocation(newLoc: XLocation = this.currentLocation) {
    var loc = iterationCopy(newLoc) as XLocation;
    var recLoc = AlertsManager.getRecentLocation(loc);
    if (recLoc) {
      loc.route = loc.route || recLoc.route;
      loc.city = loc.city || recLoc.city;
      loc.state = loc.state || recLoc.state;
      loc.lat = loc.lat || recLoc.lat;
      loc.lon = loc.lon || recLoc.lon;
    }

    var isSame = this.compare(loc, this.requestedLocation)
    if (isSame) {
      //return no-op
      log('loc=requestedLocation:' + JSON.stringify(loc), 'trace,location', 'location-manager');
      return;
    }

    this.requestedLocation = loc;
    log('requestedLocation changed:' + JSON.stringify(loc), 'trace,location', 'location-manager');

    AlertsManager.doEvent('location-changing', { loc: loc });
  }

  //from loaded data (response)
  locationChanged(reLoc: ReactionLocation) {
    log('locationChanged: ' + JSON.stringify(reLoc), 'location,trace', 'location-manager');
    var newLoc = XLocation.fromReLoc(reLoc, LocationMethod.route);

    var isSame = this.compare(this.currentLocation, newLoc);

    if (!isSame) {
      this.currentLocation = newLoc;
      this.requestedLocation = newLoc;

      if (reLoc.route) {
        this.setPath(this.tabPath, reLoc.route);
      }

      this.am.setRecentLocation(newLoc);
    }

    AlertsManager.doEvent('location-changed', { loc: newLoc });
  }

  async locUrl(url: string): Promise<string> {
    var loc = await this.getCurrentLocation();

    if (!loc.lat) {
      url = url.replace('{lat}=', '');
    }

    if (!loc.lon) {
      url = url.replace('{lon}=', '');
    }

    if (!loc.route) {
      url = url.replace('{route}=', '');
    }

    return url
      .replace("{lat}", loc.lat ? loc.lat.toString() : '0')
      .replace("{lon}", loc.lon ? loc.lon.toString() : '0')
      .replace("{route}", loc.route || '');
  }

  routeChanged(data: any) {
    var route = data.route.toLowerCase();

    var excluded = ['/edit', '/profile'];
    for (var i = 0; i < excluded.length; i++) {
      if (route.indexOf(excluded[i]) === 0) {
        return;
      }
    }

    var coreRoute = '';
    var prefs = ['/home', '/alerts', '/statuses', '/weather', '/cameras', '/roads', '/rivers', '/settings', '/about', '/page', '/local', '/manage'];
    for (var i = 0; i < prefs.length; i++) {
      if (route.indexOf(prefs[i]) === 0) {
        coreRoute = prefs[i];
        route = route.substring(prefs[i].length);
      }
    }

    if (coreRoute === '/settings') {
      return;
    }

    var loc: XLocation = null;

    if (route.length > 2) {
      //////this.currentRoute = route;
      log('route set to:' + route, 'trace,route', 'location-manager');
      loc = { route: route, method: LocationMethod.route };
      this.changeLocation(loc);
    } else {
      log('route set to default', 'trace,route', 'location-manager');
      loc = this.currentLocation || this.defaultLocation;
      this.changeLocation(loc);
    }
  }

  async gotoCurrentLocation(force: boolean = false) {
    if (force) {
      await AlertsManager.instance.lm.getCurrentLocation();
    }

    this.changeLocation();
  }

  async gotoHomeLocation() {
    this.changeLocation(AlertsManager.instance.sm.getHomeLocation());
  }

  async toggleCurrentLocation() {
    var isCurrent = this.am.settings && this.am.settings.location && this.am.settings.location.currentLocationEnabled;
    if (isCurrent) {
      await AlertsManager.userRequestedLocationSpecial({ current: false });
      await this.gotoHomeLocation();
    } else {
      this.am.settings.nonCurrentLocation = iterationCopy(this.am.settings.recentLocation) as SavedLocation;
      await AlertsManager.userRequestedLocationSpecial({ current: true });
    }
  }

  //------------------------------------------------------------------------------------------------//
  /*
      routeChanged(data: any) {
          //alert("route:" + data.route);
          var route = data.route.toLowerCase();
  
          var excluded = ['/edit', '/profile'];
          for (var i = 0; i < excluded.length; i++) {
              if (route.indexOf(excluded[i]) === 0) {
                  return;
              }
          }
  
          var coreRoute = '';
          var prefs = ['/home', '/alerts', '/statuses', '/weather', '/cameras', '/roads', '/rivers', '/settings'];
          for (var i = 0; i < prefs.length; i++) {
              if (route.indexOf(prefs[i]) === 0) {
                  coreRoute = prefs[i];
                  route = route.substring(prefs[i].length);
              }
          }
  
          if (route.length > 2 && coreRoute !== '/settings') {
              //////this.currentRoute = route;
              log('route set to:' + route, 'trace,route', 'location-manager');
              this.setLocation({ route: route, isInternal: coreRoute === '/settings' });
          }
          //alert('route:' + route);
      }
  
  
  
      async setLocation(options: any) {
          if (typeof options === 'undefined' || options.name === 'undefined, undefined') {
              log('bogus setting location:' + JSON.stringify(options), 'trace,location', 'location-manager');
              return;
          }
  
          log('setting location:' + JSON.stringify(options), 'trace,location', 'location-manager');
          var changed = false;
          var changedFromRoute = false;
          var oldRoute = this.currentRoute;
  
          if (!AlertsManager.instance.settings) {
              this.loadSettings();
          }
  
          var settings = AlertsManager.instance.settings;
  
          if (typeof options.current === 'boolean') {
              settings.location.currentLocationEnabled = options.current;
              changed = true;
          }
  
          if (typeof options.lat === 'number') {
              settings.location.currentLocationEnabled = false;
              settings.recentLocation = new SavedLocation();
              settings.recentLocation.city = options.city;
              settings.recentLocation.state = options.state;
              log('set settings.recentLocation with options:' + options.city + ',' + options.state, 'trace,location', 'location-manager');
  
              this.saveCoords(options);
              this.cityName = options.city + ', ' + options.state;
              this.currentRoute = (options.state + '/' + options.city).toLowerCase();
              changed = true;
  
              var recLoc = settings.recentLocations.find(c => c.name == this.cityName);
              if (!recLoc) {
                  var c = {
                      name: this.cityName,
                      lat: this.coordinates.latitude,
                      lon: this.coordinates.longitude,
                      city: options.city,
                      state: options.state,
                      country: '',
                      order: 0,
                      dataRetrievedAt: ''
                  };
  
                  if (c.city) {
                      if (!c.name) {
                          c.name = c.city + ', ' + c.state;
                      }
  
                      log('adding: ' + JSON.stringify(c), 'location,trace', 'location-manager');
  
                      settings.recentLocation.name = settings.recentLocation.city + ", " + settings.recentLocation.state;
                      log('set settings.recentLocation.name with :' + settings.recentLocation.name, 'trace,location', 'location-manager');
                      settings.recentLocations.push(c);
                  }
  
                  settings.recentLocation = iterationCopy(c) as SavedLocation;
                  log('set settings.recentLocation with :' + JSON.stringify(settings.recentLocation), 'trace,location', 'location-manager');
                  settings.save();
  
                  if (c.lat === options.lat && c.lon === options.lon) {
                      this.currentRoute = (c.state + '/' + c.city).toLowerCase();
                      log('1:current route set to ' + this.currentRoute, 'trace,location', 'location-manager');
                  } else {
                      this.currentRoute = '/z/' + options.lat + ',' + options.lon;
                      log('2:current route set to ' + this.currentRoute, 'trace,location', 'location-manager');
                  }
              } else {
                  if (recLoc.lat !== options.lat || recLoc.lon !== options.lon) {
                      this.currentRoute = '/z/' + options.lat + ',' + options.lon;
                      log('5:current route set to ' + this.currentRoute, 'trace,location', 'location-manager');
                  }
              }
          }
  
          if (typeof options.route === 'string') {
              this.currentRoute = options.route;
              log('3:current route set to ' + this.currentRoute, 'trace,location', 'location-manager');
              changed = true;
              changedFromRoute = true;
              if (oldRoute !== this.currentRoute) {
                  await getUrl(this.urlLocate.replace('{route}', options.route), true).then(async data => {
                      var city = data.cities[0];
                      await this.setLocation({
                          lat: city.lat,
                          lon: city.lon,
                          city: city.city,
                          state: city.state
                      })
                  });
              }
  
              // return;
          }
  
          if (typeof options.city === 'string') {
              // var cityInfo = await 
          }
  
          if (changed) {
              if (this.tabPath && oldRoute !== this.currentRoute) {
                  history.pushState(null, '', '/' + this.tabPath + '/' + this.currentRoute);
  
                  if (typeof options.isInternal === 'boolean' && options.isInternal) {
                      //ignore
                  } else {
                      await AlertsManager.doEvent('location-changed');
                      await AlertsManager.doEvent('data-loaded');
                      log('set location -> location-changed', 'location,trace', 'location-manager');
                  }
              } else {
                  log('set location -> location-changed (partial)', 'location,trace', 'location-manager');
                  if (changedFromRoute) {
                      await AlertsManager.doEvent('location-changed');
                  }
              }
              //remove route from url
              //this.truncateRoute();
              //history.pushState(null, '', this.tabPath + '/' + path) //HERE
  
              if (typeof options.isInternal === 'boolean' && options.isInternal) {
                  //ignore
              } else {
                  await AlertsManager.getWoke(true);
                  AlertsManager.doEvent('tabs-refresh');
              }
          }
      }
  
      truncateRoute() {
          var route = window.location.pathname;
  
          var tab = '';
          var prefs = ['/home', '/alerts', '/statuses', '/weather', '/cameras', '/roads', '/rivers', '/settings'];
          for (var i = 0; i < prefs.length; i++) {
              if (route.indexOf(prefs[i]) === 0) {
                  tab = prefs[i];
                  if (this.tabPath.indexOf('profile') === -1) {
                      history.pushState(null, '', tab);
                  }
              }
          }
      }
  
      async getLocation(): Promise<Coordinates> {
          log('getting location', 'trace,location', 'location-manager');
          var promise = new Promise<Coordinates>(async (resolve) => {
              this.verifySettings();
  
              if (!this.settings.location || !this.settings.location.currentLocationEnabled) {
                  //can't use current location
                  if (!this.coordinates || this.coordinates.latitude === null || typeof this.coordinates.latitude === 'undefined' ||
                      (this.coordinates.latitude === 0 && this.coordinates.longitude === 0)) {
                      if (this.settings.recentLocation && typeof this.settings.recentLocation.lat !== 'undefined' && this.settings.recentLocation.lat !== 0) {
                          log('using location(' + JSON.stringify(this.settings.recentLocation) + ")", 'trace,location', 'location-manager');
                          this.setLocation(this.settings.recentLocation);
                          this.cityName = this.settings.recentLocation.name;
                          AlertsManager.doEvent('tabs-refresh');
                          //this.saveCoords({ lat: this.settings.recentLocation.lat, lon: this.settings.recentLocation.lon });
                      } else {
                          this.cityName = '(locating)';
                          this.saveCoords({ lat: 0, lon: 0 });
                          log('using location(0,0)', 'trace,location', 'location-manager');
                      }
                  }
  
                  resolve(this.coordinates);
                  return this.coordinates;
              }
  
              if ("geolocation" in navigator) {
                  await navigator.geolocation.getCurrentPosition((position) => {
  
                      var co = {
                          lat: Math.floor(position.coords.latitude * 100) / 100,
                          lon: Math.floor(position.coords.longitude * 100) / 100
                      }
  
                      log('using geolocation(' + co.lat + ',' + co.lon + ')', 'trace,location', 'location-manager');
                      this.coordinates = position.coords;
                      window.coords = this.coordinates;
                      //////this.currentRoute = '/z/' + co.lat + ',' + co.lon;
                      this.setLocation(co);
  
                      resolve(position.coords);
                      return this.coordinates;
                  });
              }
              else {
                  log("geolocation is not supported in this environment", 'error,location', 'location-manager');
                  this.coordinates = {
                      latitude: 0,
                      longitude: 0,
                      accuracy: 0,
                      altitude: 0,
                      altitudeAccuracy: 0,
                      heading: 0,
                      speed: 0
                  }
  
                  this.cityName = '(locating)';
  
                  window.coords = this.coordinates;
                  resolve(this.coordinates);
                  return this.coordinates;
              }
          });
  
          return promise;
      }
  
      saveCoords(options: any) {
  
          if (options.latitude && !options.lat) {
              options.lat = options.latitude;
              options.lon = options.longitude;
          }
  
          log('saving coords(' + options.lat + ',' + options.lon + ')', 'trace,location', 'location-manager');
          if (!this.settings) {
              this.loadSettings();
          }
  
          if (!this.settings.recentLocation) {
              this.settings.recentLocation = new SavedLocation();
          }
  
          this.coordinates = {
              latitude: options.lat,
              longitude: options.lon,
              accuracy: 0,
              altitude: 0,
              altitudeAccuracy: 0,
              heading: 0,
              speed: 0
          };
  
          window.coords = this.coordinates;
  
          this.settings.recentLocation.lat = options.lat;
          this.settings.recentLocation.lon = options.lon;
          this.settings.recentLocation.city = options.city || null;
          this.settings.recentLocation.state = options.state || null;
          this.settings.recentLocation.name = options.name || null;
  
          this.settings.save();
      }
      */
}