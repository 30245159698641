import { getUrl, zmoment } from '../../helpers/utils';
import { AlertsManager, ReactionMessage, ReactionPriority, ReactionStateType, ReactionDataCategory } from '../alerts-manager/alerts-manager';
import { ToastButton, toastController } from '@ionic/core';
import moment from 'moment';

export class NoaaManager {
  isLoaded: boolean = false;
  dataNOAACore: NoaaCore;
  dataNOAAWarnings: NOAAWarnings;
  dataNOAAForecast: NOAAWeather;
  dataNOAAHourlyForecast: NOAAWeather;
  urlNoaa: string = "https://api.weather.gov/points/{lat},{lon}";
  urlNoaaAlerts: string = 'https://api.weather.gov/alerts/active/zone/{zone}';
  urlWarning: string = 'https://forecast.weather.gov/showsigwx.php?warnzone={zone}&warncounty={county}&firewxzone={firezone}&local_place1={place}&product1=Hazardous+Weather+Outlook&lat={lat}&lon={lon}';
  previousNOAAWarnings: any = [];
  dataRetrieved: Date = null;
  dataRequested: Date = null;
  dataInterval: number = 60; //seconds
  dataRequestInterval: number = 5; //seconds
  previousLatLon: any;

  dataKeys: NOAAKeys = new NOAAKeys();

  constructor() {
    AlertsManager.onEvent('NoaaManager', 'noaacore-loaded', {
      fn: () => {
        this.handleNoaaCore();
        //this.popupIfWarnings();
      }
    });
    AlertsManager.onEvent('NoaaManager', 'noaawarnings-loaded', {
      fn: () => {
        this.handleNoaaWarnings();
      }
    });
    AlertsManager.onEvent('NoaaManager', 'location-changed', {
      fn: () => {
        this.load(true)
      }
    });
  }

  async load(force: boolean = false) {
    var now = moment();

    var tooSoon = this.dataRequested !== null && zmoment(this.dataRequested).add(this.dataRequestInterval, 'seconds') > now;
    var dataNeeded = this.dataRetrieved === null || zmoment(this.dataRetrieved).add(this.dataInterval, 'seconds') < now;

    var loc = await AlertsManager.getLocation();
    if (this.previousLatLon == null) {
      this.previousLatLon = loc;
    }
    var newLocation = loc.lat !== this.previousLatLon.lat || loc.lon !== this.previousLatLon.lon;

    if ((force && (dataNeeded && !tooSoon)) || newLocation) {
      this.dataRequested = new Date();
      this.previousLatLon = loc;

      console.debug('NOAA RETRIEVED: ' + now);

      if (typeof loc === 'undefined' || loc === null || typeof loc.lat === 'undefined' || (loc.lat === 0 && loc.lon === 0)) {
        return;
      }

      var urlNoaa = this.urlNoaa.replace("{lat}", loc.lat.toString()).replace("{lon}", loc.lon.toString());

      //not await this one... (weather - lazy load) - non-hourly
      getUrl(urlNoaa, false, 'application/geo+json').then((data) => {
        this.dataNOAACore = data;
        this.dataRetrieved = new Date();
        AlertsManager.doEvent('noaacore-loaded');

        // Fetch hourly forecast using the forecastHourly URL from the point data response
        var urlNoaaHourly = this.dataNOAACore.properties.forecastHourly;
        getUrl(urlNoaaHourly, false).then((data) => {
          this.dataNOAAHourlyForecast = data;
          AlertsManager.doEvent('noaahourlyforecast-loaded');
        });        
      });
    }
  }

  async presentToast(header: string, message: string, duration: number = 2000, buttons: ToastButton[] = null) {
    //const toastController = document.querySelector('ion-toast-controller') as any;

    const toast = await toastController.create({
      header: header,
      message: message,
      duration: duration,
      buttons: buttons
    });
    return await toast.present();
  }

  severityToPriority(severity: string): ReactionPriority {
    switch (severity) {
      case 'Severe': return ReactionPriority.urgent;
      case 'Moderate': return ReactionPriority.warning;
      case 'Minor': return ReactionPriority.normal;
      default: return ReactionPriority.info;
    }
  }

  urgencyToStateType(urgency: string): ReactionStateType {
    switch (urgency) {
      case 'Immediate': return ReactionStateType.threat;
      case 'Expected': return ReactionStateType.blocked;
    }
  }

  async handleNoaaWarnings() {
    var timeout = (new Date()).getTime() + 5 * 60 * 1000;
    var warnings = this.dataNOAAWarnings.features;
    if (typeof warnings !== 'undefined' && Array.isArray(warnings)) {
      for (var i = 0; i < warnings.length; i++) {
        var props = warnings[i].properties;
        //var message = props.headline;
        //var heading = props.event;

        var urlWarning = this.resolveKeys(this.urlWarning);

        var lines = [props.headline];

        var regEx = /[*][ ][A-Z ]*[\.][\.][\.]/g;
        var matches = props.description.match(regEx);
        if (matches !== null) {
          var i1 = props.description.indexOf(matches[0]);
          if (i1 > 0) {
            lines.push(props.description.substring(0, i1));
          }

          for (var i = 0; i < matches.length; i++) {
            var temp = matches[i];
            if (temp.length > 0) {
              var i1 = props.description.indexOf(temp);
              var i2 = (i < matches.length - 1) ? props.description.indexOf(matches[i + 1]) : -1;

              if (i2 === -1) {
                temp = props.description.substring(i1);
              } else {
                temp = props.description.substring(i1, i2);
              }

              lines.push("-");
              lines.push(temp);
            }
          }
        } else {
          lines.push(props.description);
        }

        lines.push(props.instruction);

        const rMessage: ReactionMessage = {
          id: -2,
          sId: props.id,
          distance: 0,
          priority: this.severityToPriority(props.severity),
          stateType: this.urgencyToStateType(props.urgency),
          state: props.status,
          title: props.event,
          link: urlWarning,
          data: {
            lines: lines
          },
          valueChanged: new Date(Date.parse(props.sent)),
          retrieved: this.dataRetrieved,
          isOld: new Date(props.expires) < new Date(),
          dataCategory: ReactionDataCategory.weather,
          dataAspect: 'alert',
          dataProperty: 'warning',
          _isPinned: false,
          _isNewValue: false,
          _source: 'weather',
          _timeout: timeout,
          order: null
        };

        (await AlertsManager.getWoke()).addMessage(rMessage);

        //var urlWarning = this.urlWarning,replace;

        // var buttons = [
        //     {
        //         text: 'See warning...',
        //         handler: () => {
        //             window.open(urlWarning);
        //         }
        //     }
        // ];

        //await this.presentToast(heading, message, 5000, buttons);
        //await this.sleep(5000);
      }
    }
  }

  async sleep(ms) {
    return await new Promise(resolve => setTimeout(resolve, ms));
  }

  getLastSegment(text: string): string {
    if (!text) {
      return '';
    }

    var index = text.lastIndexOf('/');
    var seg = text.substring(index + 1);
    return seg;
  }

  resolveKeys(url: string): string {
    url = url.replace('{zone}', this.dataKeys.zone);
    url = url.replace('{firezone}', this.dataKeys.firezone);
    url = url.replace('{county}', this.dataKeys.county);
    url = url.replace('{lat}', this.dataKeys.lat.toString());
    url = url.replace('{lon}', this.dataKeys.lon.toString());
    url = url.replace('{place}', this.dataKeys.place);

    return url;
  }

  async handleNoaaCore() {
    //var forecastUrl = this.dataNOAACore.properties['forecast'];
    this.dataKeys.zone = this.getLastSegment(this.dataNOAACore.properties.forecastZone);
    this.dataKeys.firezone = this.getLastSegment(this.dataNOAACore.properties.fireWeatherZone);
    this.dataKeys.county = this.getLastSegment(this.dataNOAACore.properties.county);
    this.dataKeys.lat = this.dataNOAACore.geometry.coordinates[1];
    this.dataKeys.lon = this.dataNOAACore.geometry.coordinates[0];
    this.dataKeys.place = this.dataNOAACore.properties.relativeLocation.properties.city + ' ' +
      this.dataNOAACore.properties.relativeLocation.properties.state;

    var urlNoaaAlerts = this.resolveKeys(this.urlNoaaAlerts);
    getUrl(urlNoaaAlerts, false).then(data => {
      this.dataNOAAWarnings = data;
      AlertsManager.doEvent('noaawarnings-loaded');
    });

    var urlNoaaForecast = this.dataNOAACore.properties.forecast;
    getUrl(urlNoaaForecast, false).then(data => {
      this.dataNOAAForecast = data;
      AlertsManager.doEvent('noaaforecast-loaded');
    });

    var urlNoaaHourlyForecast = this.dataNOAACore.properties.forecastHourly;
    getUrl(urlNoaaHourlyForecast, false).then(data => {
      this.dataNOAAHourlyForecast = data;
      AlertsManager.doEvent('noaahourlyforecast-loaded');
    });
  }  
}

//-----//

export interface NoaaCore {
  "@context": Array<ContextClass | string>;
  id: string;
  type: string;
  geometry: Geometry;
  properties: NoaaCoreProperties;
}

export interface ContextClass {
  wx: string;
  s: string;
  geo: string;
  unit: string;
  "@vocab": string;
  geometry: { [key: string]: string };
  city: string;
  state: string;
  distance: { [key: string]: string };
  bearing: CountyClass;
  value: Value;
  unitCode: { [key: string]: string };
  forecastOffice: CountyClass;
  forecastGridData: CountyClass;
  publicZone: CountyClass;
  county: CountyClass;
}

export interface CountyClass {
  "@type": string;
}

export interface Value {
  "@id": string;
}

export interface Geometry {
  type: string;
  coordinates: number[];
}

export interface NoaaCoreProperties {
  "@id": string;
  "@type": string;
  cwa: string;
  forecastOffice: string;
  gridX: number;
  gridY: number;
  forecast: string;
  forecastHourly: string;
  forecastGridData: string;
  observationStations: string;
  relativeLocation: RelativeLocation;
  forecastZone: string;
  county: string;
  fireWeatherZone: string;
  timeZone: string;
  radarStation: string;
}

export interface RelativeLocation {
  type: string;
  geometry: Geometry;
  properties: RelativeLocationProperties;
}

export interface RelativeLocationProperties {
  city: string;
  state: string;
  distance: DistanceClass;
  bearing: DistanceClass;
}

export interface DistanceClass {
  value: number;
  unitCode: string;
}

//---//


export interface NOAAWeather {
  "@context": Array<ContextClass | string>;
  type: string;
  geometry: NOAAWeatherGeometry;
  properties: Properties;
}

export interface ContextClass {
  wx: string;
  geo: string;
  unit: string;
  "@vocab": string;
}

export interface NOAAWeatherGeometry {
  type: string;
  geometries: GeometryElement[];
}

export interface GeometryElement {
  type: string;
  coordinates: Array<Array<number[]> | number>;
}

export interface Properties {
  updated: string;
  units: string;
  forecastGenerator: string;
  generatedAt: string;
  updateTime: string;
  validTimes: string;
  elevation: Elevation;
  periods: Period[];
}

export interface Elevation {
  value: number;
  unitCode: string;
}

export interface Period {
  number: number;
  name: string;
  startTime: string;
  endTime: string;
  isDaytime: boolean;
  temperature: number;
  temperatureUnit: TemperatureUnit;
  temperatureTrend: null;
  windSpeed: string;
  windDirection: string;
  icon: string;
  shortForecast: string;
  detailedForecast: string;
}

export enum TemperatureUnit {
  F = "F",
}

//---//

export interface NOAAWarnings {
  "@context": Array<ContextClass | string>;
  type: string;
  features: Feature[];
  title: string;
  updated: string;
}

export interface ContextClass {
  wx: string;
  "@vocab": string;
}

export interface Feature {
  id: string;
  type: string;
  geometry: null;
  properties: Properties2;
}

export interface Properties2 {
  "@id": string;
  "@type": string;
  id: string;
  areaDesc: string;
  geocode: Geocode;
  affectedZones: string[];
  references: any[];
  sent: string;
  effective: string;
  onset: string;
  expires: string;
  ends: string;
  status: string;
  messageType: string;
  category: string;
  severity: string;
  certainty: string;
  urgency: string;
  event: string;
  sender: string;
  senderName: string;
  headline: string;
  description: string;
  instruction: string;
  response: string;
  parameters: Parameters;
}

export interface Geocode {
  UGC: string[];
  SAME: string[];
}

export interface Parameters {
  NWSheadline: string[];
  VTEC: string[];
  PIL: string[];
  BLOCKCHANNEL: string[];
  eventEndingTime: string[];
}

//----///

export class NOAAKeys {
  zone: string;
  firezone: string;
  county: string;
  lat: number;
  lon: number;
  place: string;
}