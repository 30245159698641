import { getUrl, log, iterationCopy, logError, getAllItemsWhere, zmoment } from '../../helpers/utils';
import { NoaaManager } from '../noaa-manager/noaa-manager';
import { LocationManager } from '../alerts-manager/location-manager';
import { SettingsManager } from '../alerts-manager/settings-manager';
import moment from 'moment';
import { PurpleAirItem } from '../../global/types';

declare global {
    interface Window { coords }
}

export class AlertsManager {
    static instance: AlertsManager = null;

    lm: LocationManager = null;
    sm: SettingsManager = null;
    noaaManager: NoaaManager = null;

    isLoaded: boolean = false;
    isLoading: boolean = false;
    data: ReactionResponse;
    previousData: ReactionResponse;
    urlRoot: string = "$api$";
    urlDebugRoot: string = "https://localhost:44349";
    url: string = this.urlRoot + "/api/reaction?lat={lat}&lon={lon}&route={route}";
    urlActivate: string = this.urlRoot + "/api/login?h={hash}&rx={rx}";
    urlFind: string = this.urlRoot + "/api/cities?city={city}";
    eCode: any = '';
    hx: any = '';
    rx: any = '';
    events: any = {};
    frame: any = null;
    lastRetrieved: number = 0;
    lastUrl: string = '';

    settings: Settings = null;
    tickTimer: any = null;
    refreshDataSeconds: number = 120;

    constructor() {
        if (AlertsManager.instance === null) {
            AlertsManager.instance = this;
            this.lm = new LocationManager(this);
            this.sm = new SettingsManager(this);
            this.noaaManager = new NoaaManager();

            AlertsManager.onEvent('alerts-manager', 'location-changing', {
                fn: () => this.load(true)
            });

            AlertsManager.onEvent('alerts-manager', 'data-loaded', {
                fn: () => this.locationChanged(this.data.location)
            });

            this.tickTimer = window.setInterval(() => this.onTick(), 1000 * 60 * 2);

            window.addEventListener('focus', () => this.dataRefreshCheck(false));
        }

        this.sm.loadSettings(true);

    }

    //core items ---------------------------------------------

    onTick() {
        this.dataRefreshCheck();
        AlertsManager.doEvent('tick');
    }

    dataRefreshCheck(force: boolean = false) {
        //check focus, timing
        var now = (new Date()).getTime();
        if (!force && this.data) {
            var gotData = moment(this.data.retrieved).valueOf();
            var diff = (now - gotData) / 1000;
            force = diff >= this.refreshDataSeconds && document.hasFocus();
        }

        if (force) {
            this.load(force);
        }
    }

    static get() {
        return AlertsManager.instance === null ? new AlertsManager() : AlertsManager.instance;
    }

    static async getWoke(force: boolean = false) {
        log('got woke', 'trace', 'alerts-manager');
        var inst = AlertsManager.get();
        if (!force && inst && inst.isLoaded && inst.data !== null) {
            return inst;
        }

        if (!inst.data || inst.data === null) {
            await inst.load(force);
        }

        return inst;
    }

    public static async getPurpleAir() {
        return AlertsManager.getWoke(false).then(am => am.data.purpleAir);
    }

    static async getAll(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data);
    }

    static async getAlerts(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.statuses : null);
    }

    static async getInfo(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.helpfulLinks : null);
    }

    static async getCameras(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.cameras : null);
    }

    static async getRoads(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.roads : null);
    }

    static async getWeather(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.weather : null);
    }

    static async getLocal(force: boolean = false) {
        return AlertsManager.getWoke(force).then(am => am.data ? am.data.local : null);
    }

    addMessageToGroup(group: ReactionCoreSection, m: ReactionMessage) {
        var mPrevious = group.messages.findIndex(mp => mp.sId === m.sId && mp.id === m.id);
        if (mPrevious > -1) {
            group.messages[mPrevious] = m;
        }
        else {
            group.messages.push(m);
        }
    }

    addMessage(m: ReactionMessage) {
        var isWeather = m.dataCategory === ReactionDataCategory.weather;
        var isStatus = m.priority === ReactionPriority.warning || m.priority === ReactionPriority.urgent || m.priority === ReactionPriority.critical;

        if (isWeather) {
            this.addMessageToGroup(this.data.weather, m);
        }

        if (isStatus) {
            this.addMessageToGroup(this.data.statuses, m);
        }

        if (isWeather || isStatus) {
            //AlertsManager.doEvent('data-loaded');
            AlertsManager.doEvent('data-updated');
        }
    }

    isEditing(): boolean {
        return typeof this.settings.eInfo === 'object' &&
            ((typeof this.settings.eInfo.hx === 'string' && typeof this.settings.eInfo.c === 'string')
                || (typeof this.settings.eInfo.rx === 'string' && this.settings.eInfo.rx !== ''));
    }

    //data items ---------------------------------------------

    async load(force: boolean = false) {
        log('data loading', 'trace,data', 'alerts-manager');
        if (this.isLoaded && !force) {
            return;
        }

        if (this.isLoading) {
            await AlertsManager.awaitEvent('data-finished');
            return;
        }

        this.isLoading = true;
        try {
            var url = await this.lm.locUrl(this.url);

            //settings
            try {
                var layer = this.sm.getLayer();
                if (layer !== 0) {
                    url = url + '&layer=' + layer;
                }
            } catch {
                log('getting layer failed');
            }

            var diff = Math.abs((new Date()).getTime() - this.lastRetrieved);

            if (this.lastUrl === url && diff < 3000) {
                log('url cached-skipped:' + url, 'trace,location', 'alerts-manager');
                this.dataLoaded(this.data);
                this.isLoaded = true;
                this.isLoading = false;
                return;
            }

            if (force) {
                log('url:' + url, 'trace,location', 'alerts-manager');
            } else {
                log('url(forced):' + url, 'trace,location', 'alerts-manager');
            }

            this.lastRetrieved = (new Date()).getTime();
            this.lastUrl = url;

            //this is the only full-data retrieval call
            await getUrl(url, true).then((data) => {
                this.dataLoaded(data);
                this.isLoaded = true;
            }).finally(() => {
                AlertsManager.doEvent('data-finished');
                this.isLoading = false;
            });
        } catch {
            AlertsManager.doEvent('data-finished');
            this.isLoading = false;
        }

        log('data loading finished', 'trace,data', 'alerts-manager');
    }

    processDataLoaded() {
        //handle marking as new
        var previousDataRetrieval = this.getPreviousTimeLoadedFromCity();
        if (previousDataRetrieval !== null) {
            var props = Object.getOwnPropertyNames(this.data);
            for (var i = 0; i < props.length; i++) {
                if (this.data[props[i]] !== null && this.data[props[i]].hasOwnProperty('messages')) {
                    var newSection = this.data[props[i]] as ReactionCoreSection;
                    if (newSection) {
                        var newMsgs = newSection.messages;

                        for (var j = 0; j < newMsgs.length; j++) {
                            var newMsg = newMsgs[j];
                            newMsg._isNewValue = false;
                            if (newMsg.valueChanged !== null && (previousDataRetrieval < newMsg.valueChanged)) {
                                newMsg._isNewValue = true;
                            }
                        }
                    }
                }
            }
        }

        //handle isPinned
        var pinnedIds = this.settings.pinnedIds;
        if (pinnedIds && pinnedIds.length > 0) {
            for (var i = 0; i < pinnedIds.length; i++) {
                var msg = this.findId(pinnedIds[i].id);
                if (msg !== null) {
                    msg._isPinned = true;
                }
            }
        }

        var timeout = 1000 * 60 * 10; //10 minutes
        var now = (new Date()).getTime();
        var allItems = getAllItemsWhere(this.data);
        for (var i = 0; i < allItems.length; i++) {
            var item = allItems[i];
            var tempTimeout = timeout;
            if (item.dataProperty === 'darksky') {
                tempTimeout = timeout * 2;
            }
            item._timeout = now + tempTimeout;
            if (item.retrieved && item.retrieved !== '0001-01-01T00:00:00' && item.dataProperty !== 'info') {
                item._timeout = zmoment(item.retrieved).valueOf() + timeout;
            }
        }
    }

    dataLoaded(data: any) {
        this.previousData = this.data;
        this.data = data;

        try {
            this.processDataLoaded();
        } catch (e) {
            logError(e);
        }

        this.setPreviousTimeLoadedFromCity(this.data.retrieved);

        this.lm.locationChanged(this.data.location);

        //limit cams to 64 max - ??? fix ???
        this.data.cameras.messages.length = Math.min(this.data.cameras.messages.length, 60)
        log('data loaded', 'trace,data', 'alerts-manager');

        //temp fix all lines & classes to exist
        this.data.statuses.messages.map(m => {
            if (m.data.lines == null) {
                m.data.lines = [];
            }

            if (m.data.classes == null) {
                m.data.classes = [];
            }
        });

        //change weather icon on app-tab
        if (this.noaaManager) {
            this.noaaManager.load();
        }

        try {
            const purpleStr = this.data.weather.messages.filter(m => m.id === 2013);
            if (purpleStr.length > 0) {
                this.data.purpleAir = JSON.parse(purpleStr[0].data.specialData);
            }
        }
        catch {
            //ignore if error
            this.data.purpleAir = null;
        }

        AlertsManager.doEvent('data-loaded');
    }

    //settings bridge ----------------------------------------

    getIsPinned(id: number) {
        return this.sm.getIsPinned(id);
    }

    setIsPinned(id: number, isPinned: boolean) {
        this.sm.setIsPinned(id, isPinned);
    }

    setMeta(msg: ReactionMessage) {
        var id = msg.id;
        var isPinned = msg._isPinned;
        if (this.getIsPinned(id) !== isPinned) {
            this.setIsPinned(id, isPinned);
        }
    }

    resetSettings() {
        this.sm.resetSettings();
    }

    loadSettings() {
        this.sm.loadSettings();
    }

    static getRecentLocation(locator: XLocation): XLocation {
        return AlertsManager.instance.sm.getRecentLocation(locator);
    }

    static FC(temp: number) {
        if (typeof temp === 'string') {
            temp = parseFloat(temp as any);
        }
        if (AlertsManager.instance.settings.ui.celsius) {
            return Math.floor((temp - 32) * (5 / 9) + .5) + "°C";
        }

        return Math.floor(temp + .5) + "°F";
    }

    static FCTranslate(text: string) {
        if (text === null) {
            return text;
        }

        var regExF = /[0-9]*[°][F]/;
        //var regExC = /[0-9]*[°][C]/;

        if (AlertsManager.instance.settings.ui.celsius) {
            var matches = text.match(regExF);
            if (matches === null) {
                return text;
            }
            for (var i = 0; i < matches.length; i++) {
                var temp = matches[i];
                if (temp.length > 0) {
                    var xtemp = temp.substr(0, temp.length - 2);
                    var itemp = parseInt(xtemp);
                    text = text.replace(temp, AlertsManager.FC(itemp));
                }
            }
        }

        return text;
    }

    //location bridge ----------------------------------------

    setRecentLocation(loc: XLocation) {
        this.sm.setRecentLocation(loc);
    }

    static async findCities(city: string) {
        return await getUrl(AlertsManager.instance.urlFind.replace('{city}', city), true) as CityResponse;
    }

    static async userRequestedLocation(loc: XLocation) {
        await AlertsManager.instance.lm.changeLocation(loc);
    }

    static async userRequestedLocationSpecial(options: any) {
        if (typeof options.current === 'boolean') {
            AlertsManager.instance.sm.setCanUseCurrentLocation(options.current);
            if (options.current) {
                await AlertsManager.instance.lm.gotoCurrentLocation(true);
            }
        }

        if (typeof options.special === 'string') {
            if (options.special === 'recent') {
                //?
            }
        }
    }

    static async userRequestedLocationCity(city: City) {
        var loc: XLocation = {
            lat: city.lat,
            lon: city.lon,
            city: city.city,
            state: city.state,
            route: ('/' + city.state + '/' + city.city).toLowerCase(),
            method: LocationMethod.cityState
        };

        await AlertsManager.userRequestedLocation(loc);
    }

    static async userRequestedSavedLocation(savedLoc: SavedLocation) {
        var loc: XLocation = {
            lat: savedLoc.lat,
            lon: savedLoc.lon,
            city: savedLoc.city,
            state: savedLoc.state,
            route: ('/' + savedLoc.state + '/' + savedLoc.city).toLowerCase(),
            method: LocationMethod.cityState
        };

        await AlertsManager.userRequestedLocation(loc);
    }

    static getCityName(fromData: boolean = true): string {
        return AlertsManager.instance.lm.getCityName(fromData);
    }

    static async getRoutedUrl(url: string): Promise<string> {
        return await AlertsManager.instance.lm.locUrl(url);
    }

    static async getLocation(): Promise<XLocation> {
        return await AlertsManager.instance.lm.getLocation();
    }

    static getLocationSync(fromData: boolean = true): XLocation {
        return AlertsManager.instance.lm.getLocationSync(fromData);
    }

    async locationChanged(loc: ReactionLocation) {
        return await this.lm.locationChanged(loc);
    }

    static setDefaultLocation(loc: XLocation) {
        return AlertsManager.instance.lm.setDefaultLocation(loc);
    }

    //events --------------------------------------------------

    static async onEvent(source: string, name: string, data: any) {
        var inst = await AlertsManager.get();
        var eventList = inst.events[name] || [];
        var single = eventList.indexOf(e => e.source === source);
        data.source = source;
        if (single > -1) {
            eventList.splice(single, 1);
        }
        eventList.push(data);
        inst.events[name] = eventList;
        log('added:' + name + " from " + source, 'events', 'alerts-manager');
    }

    static async awaitEvent(name: string) {
        await new Promise(async resolve => {
            var rnd = (new Date()).getTime(); // Math.floor(Math.random() * 1000000);
            AlertsManager.onEvent('alerts-manager-await-' + rnd, name, {
                fn: () => {
                    resolve(null);
                }
            });
        })
    }

    static async doEvent(name: string, data: any = null) {
        var inst = await AlertsManager.get();
        var eventList = inst.events[name] || [];
        for (var i = 0; i < eventList.length; i++) {
            if (typeof eventList[i].fn === 'function') {
                if (data === null) {
                    eventList[i].fn()
                }
                else {
                    eventList[i].fn(data);
                }
            }
        }
        log('fired:' + name, 'events', 'alerts-manager');
    }

    //utils------------------------------------------------

    findId(id: number) {
        return this.data.cameras.messages.find(c => c.id === id) ||
            this.data.statuses.messages.find(c => c.id === id) ||
            this.data.roads.messages.find(c => c.id === id) ||
            this.data.weather.messages.find(c => c.id === id) ||
            this.data.helpfulLinks.messages.find(c => c.id === id) ||
            this.data.appInfo.messages.find(c => c.id === id) || null;
    }

    // ??? ------------------------------------------------

    getPreviousTimeLoadedFromCity() {
        var recent = this.settings.recentLocations.find(l => l.city === this.data.location.city && l.state === this.data.location.state);
        if (typeof recent !== 'undefined' && recent !== null) {
            return recent.dataRetrievedAt;
        }

        return null;
    }

    setPreviousTimeLoadedFromCity(retrievedAt: Date | string) {
        var recent = this.settings.recentLocations.find(l => l.city === this.data.location.city && l.state === this.data.location.state);
        if (typeof recent !== 'undefined' && recent !== null) {
            recent.dataRetrievedAt = retrievedAt;
            this.settings.save();
        }

        return null;
    }

    //edit --------------------------------------------------

    static async activate(hash: string): Promise<boolean> {
        var inst = AlertsManager.get();
        return await getUrl(inst.urlActivate.replace('{hash}', hash), true).then(r => {
            inst.hx = hash;
            inst.eCode = r.eCode;
            inst.settings.eInfo.hx = hash;
            inst.settings.eInfo.c = r.eCode;
            inst.settings.save();
            return true;
        }).catch(() => { return false; })
    }

    static setResult(result: string) {
        var inst = AlertsManager.get();
        inst.rx = result;
        inst.settings.eInfo.rx = result;
        inst.settings.save();
    }
}

///-----------------------------------///

export class AlertsDataV1 {
    AlertLevel?: number;
    AlertParse?: number;
    Area?: number;
    Category: number;
    Class?: string;
    Class1?: string;
    Class2?: string;
    Class3?: string;
    Distance: number;
    ID: number;
    Line1?: string;
    Line2?: string;
    Line3?: string;
    Link: string;
    LinkName: string;
    Name?: string;
    Opacity: number;
    Order: number;
    Processed: boolean;
    Resolved: boolean;
    ShowOnlyOnAlert: boolean;
    Title: string;
    Type: number;
}

export interface ReactionResponse {
    valueChanged: Date | string;
    retrieved: Date | string;
    version: number;
    status: ReactionStatus;
    appInfo: ReactionCoreSection;
    statuses: ReactionCoreSection;
    roads: ReactionCoreSection;
    cameras: ReactionCoreSection;
    helpfulLinks: ReactionCoreSection;
    weather: ReactionCoreSection;
    local: ReactionCoreSection;
    location: ReactionLocation;

    purpleAir?: PurpleAirItem[];
}

export interface ReactionStatus {
    status: ReactionStatusType;
    message: string;
}

export enum ReactionStatusType {
    unknown,
    success,
    limited,
    error,
    maintenance
}

export interface ReactionLocation {
    latitude: number;
    longitude: number;
    cityLatitude: number;
    cityLongitude: number;
    city: string;
    state: string;
    country: string;
    isNamePreferred: boolean;
    source: string;
    route: string;
    zip: string;
}

export interface LocalItem {
    Name: string;
    Phone: string;
    Web: string;
    Menu: string;
    Ordering: string;
    HoursText: string;
    Address: string;
    Lat: number;
    Lon: number;
}

export interface ReactionCoreSection {
    status: ReactionStatus;
    messages: ReactionMessage[];
}

export enum ReactionDataCategory {
    unknown = "Unknown",
    traffic = "Traffic",
    weather = "Weather",    //incl burn bans/air quality/snowpack
    school = "School",
    services = "Services",   //power, internet, water
    government = "Government", //incl police messages, amber alerts
    community = "Community",  //events?
    business = "Business"   //ads?
}

export enum ReactionStateType {
    normal = "normal",   //open, normal
    partial = "partial", //restricted, upcoming
    blocked = "blocked", //closed, ban
    threat = "threat",   //(it's coming to you) - tornado, etc
    unknown = "unknown", //needs to be classified
    none = "none"        //not state related
}

export interface ReactionClientInfo {
    _isPinned: boolean;
    _isNewValue: boolean;
    _source: string;
    _timeout: number;
}

export interface ReactionCoreInfo extends ReactionClientInfo {
    sId?: string;
    distance: number;
    valueChanged: Date | string;
    retrieved: Date | string;
    isOld: boolean; //is old data? if so, a request to update was sent - client should recheck for an update soon
    dataCategory: ReactionDataCategory;
    dataAspect: string;
    dataProperty: string;
}

export interface ReactionMessage extends ReactionCoreInfo {
    id: number;
    priority: ReactionPriority;
    stateType: ReactionStateType;
    state: string;
    title: string;
    class?: string;
    data: ReactionData;
    image?: string;
    link: string;
    icon?: string;
    dataLink?: string;
    dataLinkType?: string;

    order: number | null; //virtually set on load with manage
    xOpen?: any;
}

export interface ReactionData {
    lines?: string[];
    classes?: string[];
    location?: string; //type 6 {City} for PSE ??
    specialData?: string;
}

export enum ReactionPriority {
    normal = "Normal",
    info = "Info",    //lower than normal
    warning = "Warning", //higher than normal
    urgent = "Urgent",  //higher than warning
    critical = "Critical" //override any turn-off features
}

export interface ReactionRoadSection {
    Distance: number;
    Title: string;
    Items: ReactionRoadItem[];
    Source: ReactionMessage;
}

export interface ReactionRoadItem {
    Distance: number;
    Title: string;
    Priority: ReactionPriority;
    StateType: ReactionStateType;
    State: string;
    Description: string;
}

export class LocationSettings {
    currentLocationEnabled: boolean = false;
}

export class EInfo {
    hx: string;
    c: string;
    rx: string;
}

export class UI {
    nightmode: boolean = false;
    celsius: boolean = false;
    pinnedFirst: boolean = false;
    imageSize: number = 3;
    hideE: boolean = false;
    testLayer: number = 0;
}

export class SavedSettings {
    version: number = 1;
    pinnedLocations: SavedLocation[];
    recentLocations: SavedLocation[];
    pinnedIds: PinnedId[];
    notifyIds: NotifyId[];
    usage: Usage = new Usage();
    location: LocationSettings;
    eInfo: EInfo;
    recentLocation: SavedLocation = new SavedLocation();
    ui: UI = new UI()
    homeLocation: HomeLocation = new HomeLocation();
    //saved setting 1/5

    load() {
        //TODO - VERIFY ALL FIELDS and add if missing (so updates can occur) copyover

        if (localStorage.hasOwnProperty('settings')) {
            try {
                var settings = JSON.parse(localStorage.getItem('settings')) as SavedSettings;
                this.version = settings.version;
                if (settings.pinnedLocations) {
                    this.pinnedLocations = settings.pinnedLocations;
                }
                if (settings.recentLocations) {
                    this.recentLocations = settings.recentLocations;
                }
                if (settings.pinnedIds) {
                    this.pinnedIds = settings.pinnedIds;
                }
                if (settings.notifyIds) {
                    this.notifyIds = settings.notifyIds;
                }
                if (settings.usage) {
                    this.usage = settings.usage;
                }
                if (settings.location) {
                    this.location = settings.location;
                }
                if (settings.eInfo) {
                    this.eInfo = settings.eInfo;
                    if (this.eInfo && this.eInfo.hx && this.eInfo.c) {
                        AlertsManager.instance.eCode = this.eInfo.c;
                        AlertsManager.instance.hx = this.eInfo.hx;
                        AlertsManager.instance.rx = this.eInfo.rx;
                    }
                }
                if (settings.recentLocation) {
                    this.recentLocation = iterationCopy(settings.recentLocation) as SavedLocation;
                    if (!this.recentLocation.route && this.recentLocation.city) {
                        this.recentLocation.route = ('/' + this.recentLocation.state + '/' + this.recentLocation.city).toLowerCase();
                    }
                    log('retr location from settings:' + JSON.stringify(settings.recentLocation), 'storage', 'alerts-manager');
                }
                if (settings.ui) {
                    this.ui = settings.ui;

                    if (typeof this.ui.imageSize === 'undefined') {
                        this.ui.imageSize = 3;
                    }
                }

                if (settings.homeLocation) {
                    this.homeLocation = settings.homeLocation;
                }
                //saved setting 2/5

                if (typeof settings.usage.startCount === 'undefined') {
                    settings.usage.startCount = 0;
                }
            }
            catch {
                //ignore
            }
        }
    }

    save() {
        log('saving settings', 'storage', 'alerts-manager');

        var settings = {
            version: this.version,
            pinnedLocations: this.pinnedLocations,
            recentLocations: this.recentLocations,
            pinnedIds: this.pinnedIds,
            notifyIds: this.notifyIds,
            usage: this.usage,
            eInfo: this.eInfo,
            recentLocation: this.recentLocation,
            ui: this.ui,
            homeLocation: this.homeLocation
            //saved setting 3/5
        };

        localStorage.setItem('settings', JSON.stringify(settings));
    }

}

//-----------------------------------------------//

export class Settings extends SavedSettings {
    constructor() {
        super();
        this.recentLocations = [];
        this.pinnedLocations = [];
        this.pinnedIds = [];
        this.notifyIds = [];
        this.location = new LocationSettings();
        this.eInfo = new EInfo();
        this.recentLocation = new SavedLocation();
        this.ui = new UI();
        this.homeLocation = new HomeLocation();
        this.homeLocation.useRecentLocation = true; //default
        //saved setting 4/5
    }

    nonCurrentLocation: SavedLocation = null;
}

//saved setting 5/5 = any new classes/properties

export interface City {
    city?: string;
    state?: string;
    country?: string;
    lat: number;
    lon: number;
    special?: string;
    zip?: string;
}

export class SavedLocation implements City {
    lat: number = 0;
    lon: number = 0;
    name: string;
    order: number = 0;

    city?: string;
    state?: string;
    country?: string;
    zip?: string;

    route?: string;

    dataRetrievedAt: Date | string;
}

export class HomeLocation extends SavedLocation {
    useCurrentLocation: boolean = false;
    useRecentLocation: boolean = false;
}

export interface PinnedId {
    id: number;
}

export interface NotifyId {
    id: number;
}

export class Usage {
    timeInApp: number;
    startCount: number;
}

export interface CityResponse {
    cities: City[];
}

export enum LocationMethod {
    unknown = 0,
    latLon = 1,
    cityState = 2,
    route = 3
}

export class XLocation {
    method: LocationMethod;
    lat?: number;
    lon?: number;
    city?: string;
    state?: string;
    route?: string;
    zip?: string;

    static fromReLoc?(reLoc: ReactionLocation, method: LocationMethod = LocationMethod.unknown): XLocation {
        var loc = {
            lat: reLoc.latitude || reLoc.cityLatitude,
            lon: reLoc.longitude || reLoc.cityLongitude,
            city: reLoc.city,
            state: reLoc.state,
            route: reLoc.route,
            zip: reLoc.zip,
            method: method
        }

        return loc;

        // if (loc.method === LocationMethod.unknown) {
        //   if (reLoc.route) {
        //     loc.method = reLoc.route.indexOf('/z/') ? LocationMethod.latLon : LocationMethod.
        //       if() {
        //     }
        //   }
        // }

        // if (reLoc.longitude !== 0) {
        //   var latDiff = Math.abs(reLoc.latitude - reLoc.cityLatitude);
        //   var lonDiff = Math.abs(reLoc.longitude - reLoc.cityLongitude);

        //   loc.method = (latDiff <= 0.01 && lonDiff <= 0.01 ? LocationMethod.cityState : LocationMethod.latLon);
        // }
    }

    static fromSavedLoc?(savedLoc: SavedLocation, method: LocationMethod = LocationMethod.unknown): XLocation {
        var loc = {
            lat: savedLoc.lat,
            lon: savedLoc.lon,
            city: savedLoc.city,
            state: savedLoc.state,
            route: savedLoc.route,
            zip: savedLoc.zip,
            method: method
        }

        return loc;
    }

    static cityName?(loc: XLocation): string {
        return loc.city + ", " + loc.state;
    }
}

//--//

export class FrozenImage {
    id: string = '';
    data: string = '';
}

export class MappedSettings {
    path: string;

    title: string;
    type: string;

    setter: any = null;
    getter: any = null;
}

//---//

export interface WeatherResponse {
    lat: number;
    lon: number;
    summaries: WeatherResponseSummary[];
    retrieved: Date | string;
    hourSummary: string;
    daySummary: string;
    weekSummary: string;
}

export interface WeatherResponseSummary {
    datetime: Date | string;
    timespan: any;
    summary: string;
    icon: string;
    maxPoint: WeatherPoint;
    minPoint: WeatherPoint;
}

export interface WeatherPoint {
    intensity: number;
    probability: number;
    temperature: number;
    humidity: number;
    windSpeed: number;
    windGust: number;
}

//---ui---//

export enum RenderMode {
    mobile = 1,
    pc = 2
}

export interface PageType {
    componentDidLoad();
    componentDidRender();
    refresh();
}

export interface KeyValue {
    key: string;
    value: string;
}