import { AlertsManager, XLocation, Settings, SavedLocation, LocationMethod } from '../alerts-manager/alerts-manager';
import { toggleClass, log, iterationCopy, logError } from '../../helpers/utils';

export class SettingsManager {
  am: AlertsManager = null;
  settings: Settings = null;

  constructor(am: AlertsManager) {
    this.am = am;
    this.settings = this.am.settings;

    AlertsManager.onEvent('settings-manager', 'location-changed', {
      fn: e => {
        if (e.loc) {
          this.setRecentLocation(e.loc);
          // var xLoc: XLocation = e.loc;
          // var loc: SavedLocation = {
          //     lat: xLoc.lat,
          //     lon: xLoc.lon,
          //     city: xLoc.city,
          //     state: xLoc.state,
          //     dataRetrievedAt: new Date(),
          //     name: xLoc.city + ", " + xLoc.state,
          //     route: xLoc.route,
          //     order: 0
          // };

          // this.settings.recentLocation = loc;
          // this.settings.save();
        }
      }
    });
  }

  setCanUseCurrentLocation(useCurrent: boolean) {
    this.am.settings.location.currentLocationEnabled = useCurrent;
  }

  getCanUseCurrentLocation() {
    return this.am.settings && this.am.settings.location && this.am.settings.location.currentLocationEnabled;
  }

  getLayer() {
    if (typeof (this.am.settings.ui.testLayer) === 'undefined') {
      return 0;
    } else {
      return this.am.settings.ui.testLayer;
    }
  }

  setRecentLocation(loc: XLocation) {
    var settings = this.settings;

    if (typeof settings.recentLocation == 'undefined' || settings.recentLocation == null) {
      settings.recentLocation = new SavedLocation();
    }

    var isSame = loc.route === settings.recentLocation.route;

    if (!isSame) {
      log('changing location to(' + JSON.stringify(loc) + ')', 'trace,location', 'settings-manager');
      var rl = settings.recentLocation;
      rl.city = loc.city;
      rl.state = loc.state;
      rl.lat = loc.lat;
      rl.lon = loc.lon;
      rl.route = loc.route;
      rl.name = rl.city + ", " + rl.state;

      try {
        var recLoc = settings.recentLocations.find(c => c.city === rl.city && c.state === rl.state);
        if (!recLoc) {
          if (rl.city) {
            log('adding to recent locations: ' + JSON.stringify(rl), 'location,trace', 'settings-manager');
            settings.recentLocations.push(iterationCopy(rl) as SavedLocation);
          }
        }
      } catch (e) {
        logError(e);
      }

      settings.save();
      //AlertsManager.doEvent('location-changed');
    } else {
      log('already have location(' + loc.city + ')', 'trace,location', 'alerts-manager');
    }

    return !isSame;
  }

  getRecentLocation(locator: XLocation): XLocation {
    var settings = this.settings;
    try {
      var loc: SavedLocation = null;

      log('locating recentLocations from ' + JSON.stringify(locator), 'location,trace', 'settings-manager');
      if (locator.method === LocationMethod.route) {
        loc = settings.recentLocations.find(c => '/' + c.state + '/' + c.city === locator.route);
      } else {
        loc = settings.recentLocations.find(c => c.city === locator.city && c.state === locator.state);
      }

      if (!loc) {
        log('found none (recentLocations)', 'location,trace', 'settings-manager');
        return null;
      }

      log('found recentLocation: ' + JSON.stringify(loc), 'location,trace', 'settings-manager');
      return {
        city: loc.city,
        state: loc.state,
        lat: loc.lat,
        lon: loc.lon,
        route: loc.route,
        method: LocationMethod.cityState
      };
    } catch (e) {
      logError(e);
    }
  }

  //-----------------------------------------------------------------------------//

  loadSettings(firstTime: boolean = false) {
    log('loading settings', 'trace,storage', 'alert-manager');
    if (AlertsManager.instance.settings == null) {
      AlertsManager.instance.settings = new Settings();
      AlertsManager.instance.settings.load();
      log('loaded settings', 'trace,storage', 'alert-manager');

      AlertsManager.instance.hx = AlertsManager.instance.settings.eInfo.hx;
      AlertsManager.instance.eCode = AlertsManager.instance.settings.eInfo.c;
      AlertsManager.instance.rx = AlertsManager.instance.settings.eInfo.rx;

      AlertsManager.instance.sm.settings = AlertsManager.instance.settings;

      if (firstTime) {
        //on startup
        var settings = AlertsManager.instance.settings;
        var defaultLocation: SavedLocation = settings.recentLocation;
        if (settings.homeLocation.useCurrentLocation) {
          settings.location.currentLocationEnabled = true;
        } else if (!settings.homeLocation.useRecentLocation) {
          settings.location.currentLocationEnabled = false;
          defaultLocation = this.getHomeLocation();

          log('set settings.recentLocation with homeLocation:' + JSON.stringify(settings.homeLocation), 'trace,location', 'alerts-manager');
        }

        if (!settings.usage.startCount) {
          settings.usage.startCount = 1;
        } else {
          settings.usage.startCount++;
        }

        settings.save();

        AlertsManager.setDefaultLocation(XLocation.fromSavedLoc(defaultLocation));
        //AlertsManager.userRequestedSavedLocation(settings.recentLocation);
      }
    }

    var settings = AlertsManager.instance.settings;
    //act on settings here!!
    if (settings && settings.ui) {
      var ionApp = document.getElementsByTagName('ion-app');
      if (ionApp && ionApp.length > 0) {
        toggleClass(ionApp[0] as Element, 'nightmode', settings.ui.nightmode);
        toggleClass(ionApp[0] as Element, 'daymode', !settings.ui.nightmode);
      }

      if (typeof settings.ui.imageSize === 'undefined') {
        settings.ui.imageSize = 3;
      }
    }

    AlertsManager.doEvent('settings-changed');
  }

  getHomeLocation() {
    var settings = AlertsManager.instance.settings;
    var tempLoc = iterationCopy(settings.homeLocation) as SavedLocation;
    var defaultLocation = null;
    if (tempLoc.lat === 0 && tempLoc.lon === 0) {
      //settings.recentLocation = new SavedLocation();
      defaultLocation = iterationCopy(this.am.settings.nonCurrentLocation);
      if (defaultLocation.lat === 0 && defaultLocation.lon === 0) {
        defaultLocation = new SavedLocation();
      }
    } else {
      defaultLocation = tempLoc;
    }

    return defaultLocation;
  }

  resetSettings() {
    AlertsManager.instance.settings = new Settings();
    AlertsManager.instance.settings.save();
    AlertsManager.instance.settings.load();
    log('reset settings', 'trace,storage', 'alert-manager');
  }

  verifySettings() {
    if (!this.settings || !this.settings.recentLocation) {
      this.loadSettings();
    }

    if (typeof this.settings.recentLocation === 'undefined' || this.settings.recentLocation === null) {
      this.settings.recentLocation = new SavedLocation();
    }

    //coord check
  }

  getIsPinned(id: number) {
    if (this.settings.pinnedIds && this.settings.pinnedIds.length > 0) {
      return this.settings.pinnedIds.findIndex(p => p.id === id) > -1;
    }

    return false;
  }

  setIsPinned(id: number, isPinned: boolean) {
    if (!this.settings.pinnedIds) {
      this.settings.pinnedIds = [];
    }

    var index = this.settings.pinnedIds.findIndex(p => p.id === id);
    var isChanged = false;
    if (isPinned && index === -1) {
      this.settings.pinnedIds.push({ id: id });
      isChanged = true;
    } else if (!isPinned && index > -1) {
      this.settings.pinnedIds.splice(index, 1);
      isChanged = true;
    }

    if (isChanged) {
      AlertsManager.doEvent('item-pin-changed', { id: id, pinned: isPinned });
      this.settings.save();
    }
  }

}
